<template>
    <br/><br/>
    <section id="project-details-area" class="py100">
        <div class="container">
            <div class="row">
                <ContentProject :media="project.url" :image="(Object.keys(project).includes('image'))? URL+'/'+project.image:''"  :title="project.title" :description="project.content"/>
                <SideBarProject :category="project.categories" :completed="getDate(project.date)" :location="location" :phone="phone" :email="email"/>
            </div>
        </div>
    </section>
</template>

<script>
    import {ref} from 'vue'
    import axios from 'axios'
    import {ContentProject,SideBarProject} from '@/components'
    export default {
        name: 'Project',
        components: {
            ContentProject, SideBarProject
        },
        props:['slug'],
        setup(props){
            const location = ref('Valencia Town Camercial Market H Block Lahore, Punjab, Pakistan.')
            const phone = ref('(+92) 3244996300')
            const email = ref('info@slimlogix.com')
            const project = ref(false)
            const URL = ref(process.env.VUE_APP_BASE_URL)

            const getProject = () => {
                axios.get(process.env.VUE_APP_BASE_URL+"/api/projects/slug/"+props.slug).then((res) => {
                    project.value = res.data
                })
            }
            getProject()

            const getDate = (date_str) => {
                let d = new Date(date_str)
                console.log(d)
                return d.toDateString()
            }

            return {location, phone, email,project,URL,getDate}
        }
    }
</script>